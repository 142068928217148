<template>
    <landing :breadCrumbs="breadCrumbs" :shopName="shopName">
        <v-container>
            <v-row
                id="description"
                justify="center"
                :class="smAndUp ? 'mb-15' : null"
            >
                <v-col cols="auto" align="center">
                    <heading
                        ><span class="red--text">平</span
                        >井ゆりのき橋通り店</heading
                    >
                </v-col>
            </v-row>

            <!-- 紹介文 -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">アクセス</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >平井駅徒歩6分!</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">住所</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >東京都江戸川区平井4丁目5-1 2階</span
                        >
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">営業時間</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >平日13:00～22:00/土日10:00～19:00<br />※時間外は応相談</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">定休日</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >月・木・金</span
                        >
                    </div>
                </v-col>
            </v-row>

            <!-- 代表イメージ -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img height="360px" :src="imageMv"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img
                            position="bottom center"
                            height="360px"
                            :src="shopImage"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col v-if="!smAndUp" cols="12" align="center">
                    <v-icon
                        small
                        color="red darken-2"
                        @click="$vuetify.goTo('#target')"
                        >fas fa-chevron-down</v-icon
                    >
                    駅からのご案内
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <GmapMap
                            :center="center"
                            :zoom="zoom"
                            map-type-id="terrain"
                            style="width: 100%; height: 360px"
                        >
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :clickable="true"
                                :draggable="true"
                                @click="center = m.position"
                            />
                        </GmapMap>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!4v1727917535307!6m8!1m7!1sFx3L8YXtC4HPPsOuUbUEFg!2m2!1d35.70386007100653!2d139.8465807045707!3f25.060046751879888!4f5.075669668983295!5f1.9587109090973311"
                            width="100%"
                            height="360"
                            style="border:0;"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </v-card>
                </v-col>
            </v-row>

            <!-- 店舗個別メッセージ -->
            <shop-message :message="this.message" :shopImage="trainerImage" />

            <!-- ナビゲーション -->
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <heading
                        ><span id="target" class="red--text">平井駅</span
                        >からお越しの方</heading
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">1</span>.
                            改札を出て右側、南口のロータリー（交番側）にいきます。
                        </div></v-card-title
                    >
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessA"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">2</span>.
                            みずほ銀行の方に渡って、道沿いに進みます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessB"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span
                                ><span class="red--text text-md-h6">3</span>.
                                商店街に入る交差点で商店街の左側の道を進み、ゆりのき橋通りに向かいます。</span
                            >
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessC"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">4</span>.
                            ゆりのき橋通りを右側（オリンピック側）に進みます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessD"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">5</span>.
                            オリンピック側へ交差点を渡って、右に曲がり、ゆりのき橋通り沿いを進みます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessE"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">6</span>.
                            室内ゴルフ練習場の建物の2階がREVOISTです。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessF"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">7</span>.
                            建物左側の外階段で2階にあがります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessG"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">8</span>.
                            こちらがREVOIST平井ゆりのき橋通り店の入口です。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessH"></v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </landing>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import ShopMessage from '@/components/landing/ShopMessage.vue'
import Landing from '@/views/Landing.vue'
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
        document.querySelector('meta[name="description"]').setAttribute(
            'content',
            `パーソナルジム REVOISTの15号店です！
平井駅から徒歩6分、ゆりのき橋通り沿いにあるジムです！

誠実さのあるトレーナーが悩みをしっかりと聞き、その方の目標や身体の状態に合わせて、オーダーメイドでレッスンを構築していきます！
大切なのは「継続すること」です！
ひとりではなかなか運動が続かない方も多いと思いますが、トレーニングも食事も出来ることからコツコツと！
健康的な生活習慣を一緒に手に入れましょう！
`
        )
    },
    components: {
        Heading,
        FooterContents,
        ShopMessage,
        Landing,
    },
    data() {
        return {
            shopName: '平井ゆりのき橋通り店',
            center: {
                lat: 35.70407039842679,
                lng: 139.8466794679497,
            },
            zoom: 18,
            markers: [
                {
                    position: {
                        lat: 35.70407039842679,
                        lng: 139.8466794679497,
                    },
                    title: '平井ゆりのき橋通り店',
                },
            ],
            message: `パーソナルジム REVOISTの15号店です！<br />
平井駅から徒歩6分、ゆりのき橋通り沿いにあるジムです！<br /><br />
誠実さのあるトレーナーが悩みをしっかりと聞き、その方の目標や身体の状態に合わせて、オーダーメイドでレッスンを構築していきます！<br />
大切なのは「継続すること」です！<br />
ひとりではなかなか運動が続かない方も多いと思いますが、トレーニングも食事も出来ることからコツコツと！<br />
健康的な生活習慣を一緒に手に入れましょう！<br /><br />
平井ゆりのき橋通り店トレーナー：酒谷 大輝
`,
        }
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: '店舗一覧・アクセス',
                    disabled: false,
                    href: 'gyms',
                },
                {
                    text: '平井ゆりのき橋通り店',
                    disabled: true,
                    href: 'access-12',
                },
            ]
        },

        imageMv() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_15/IMG_2144-hiraiyurinokibashidori-010.png'
                : 'static/pc_access_15/IMG_2144-hiraiyurinokibashidori-010.png'
        },
        // Create a computed property for each image from A to I
        accessA() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_15/IMG_2125-hiraiyurinokibashidori-005.png'
                : 'static/pc_access_15/IMG_2125-hiraiyurinokibashidori-005.png'
        },
        accessB() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_15/IMG_2126-hiraiyurinokibashidori-004.png'
                : 'static/pc_access_15/IMG_2126-hiraiyurinokibashidori-004.png'
        },
        accessC() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_15/IMG_2128-hiraiyurinokibashidori-002.png'
                : 'static/pc_access_15/IMG_2128-hiraiyurinokibashidori-002.png'
        },
        accessD() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_15/IMG_2130-hiraiyurinokibashidori-000.png'
                : 'static/pc_access_15/IMG_2130-hiraiyurinokibashidori-000.png'
        },
        accessE() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_15/IMG_2132-hiraiyurinokibashidori-001.png'
                : 'static/pc_access_15/IMG_2132-hiraiyurinokibashidori-001.png'
        },
        accessF() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_15/IMG_2137-hiraiyurinokibashidori-006.png'
                : 'static/pc_access_15/IMG_2137-hiraiyurinokibashidori-006.png'
        },
        accessG() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_15/IMG_2145-hiraiyurinokibashidori-011.png'
                : 'static/pc_access_15/IMG_2145-hiraiyurinokibashidori-011.png'
        },
        accessH() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_15/IMG_2139-hiraiyurinokibashidori-007.png'
                : 'static/pc_access_15/IMG_2139-hiraiyurinokibashidori-007.png'
        },

        shopImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_15/IMG_2137-hiraiyurinokibashidori-006.png'
                : 'static/pc_access_15/IMG_2137-hiraiyurinokibashidori-006.png'
        },
        trainerImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/trainer/平井ゆりのき橋通り店 トレーナー.jpg'
                : 'static/landing/trainer/平井ゆりのき橋通り店 トレーナー.jpg'
        },
    },
}
</script>

<style lang="scss">
.navigation {
    min-height: 3em;
}
</style>
